import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../Layout.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'RootHome',
		component: Layout,
		children: [
			{
				path: '',
				name: 'Home',
				component: () => import('../views/Home.vue'),
				meta: {
					requiresAuth: true, isAdmin: false, 
					bread: [
						{ text: 'Home', disabled: true, href: '/' }
					], permission: 'book'
				}
			}, {
				path: 'summary-symbol/:broker/:symbol/:book',
				name: 'SummarySymbol',
				component: () => import('../views/book/SummarySymbol.vue'),
				meta: {
					requiresAuth: true, isAdmin: false,
					bread: [
						{ text: 'Home', disabled: false, href: '/#/' },
						{ text: 'Symbol Summary', disabled: true, href: '/#/summary-symbol' }
					], permission: 2
				}
			}, {
				path: 'alert',
				name: 'Alert',
				component: () => import('../views/alert/Alarm.vue'),
				meta: {
					requiresAuth: true, isAdmin: false,
					bread: [
						{ text: 'Alert', disabled: true, href: '/#/alert' }
					], permission: 'alert'
				}
			}, {
				path: 'user',
				name: 'UserManagement',
				component: () => import('../views/account/Management.vue'),
				meta: {
					requiresAuth: true, isAdmin: false, 
					bread: [
						{ text: 'Home', disabled: true, href: '/' },
						{ text: 'User Management', disabled: true, href: '/user' }
					], permission: 'user'
				}
			}, 
		]
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
	}, {
		path: '/validation',
		name: 'Validate',
		component: Layout,
		children: [
			{
				path: 'net',
				name: 'ValidateNet',
				component: () => import('../views/validate/Validate.vue'),
				meta: { requiresAuth: true, isAdmin: false,
					bread: [
						{ text: 'Validation', disabled: true, href: '/#/' },
						{ text: 'Net', disabled: false, href: '/#/validation/net' },

					], permission: ['validation', 'net']
				}
			},
		]
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
	}, {
		path: '/tools',
		name: 'Tool',
		component: Layout,
		children: [
			{
				path: 'group',
				name: 'ToolGroup',
				component: () => import('../views/tool/Group.vue'),
				meta: { requiresAuth: true, isAdmin: false,
					bread: [
						{ text: 'Tool', disabled: true, href: '/#/' },
						{ text: 'Group', disabled: false, href: '/#/tool/group' },

					], permission: ['tools', 'group']
				}
			}, {
				path: 'user-performance',
				name: 'ToolUserPerformance',
				component: () => import('../views/tool/UserPerformance.vue'),
				meta: { requiresAuth: true, isAdmin: false,
					bread: [
						{ text: 'Tool', disabled: true, href: '/#/' },
						{ text: 'User Performance', disabled: false, href: '/#/tool/user-performance' },

					], permission: ['tools', 'userPerformance']
				}
			}
		]
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
	}, {
		path: '/search',
		name: 'Search',
		component: Layout,
		children: [
			{
				path: '',
				name: 'SearchHome',
				component: () => import('../views/Search.vue'),
				meta: { requiresAuth: true, isAdmin: false,
					bread: [
						{ text: 'Order Search', disabled: true, href: '/#/search' }
					], permission: 'search'
				}
			}
		]// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
	}, {
		path: '/signin',
		name: 'Signin',
		component: () => import('../views/account/Signin.vue'),
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
	}
]

const router = new VueRouter({
  	routes
})

export default router
