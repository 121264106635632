import io from "socket.io-client";
import axios from "axios";
import store from "./store";
import * as CryptoJS from 'crypto-js'

let mutations = {
    alertCount(state) {
        state.alertBadage = {
			total: 0,
			big_lot: 0,
			mandate: 0,
			profit_taker: 0,
			locking_position: 0,
			trade_on_credit: 0,
			large_exposure: 0,
			fast_trade: 0,
			watch_list: 0,
			tick_check: 0,
			same_direction: 0,
			volume_alert: 0,
		}
		Object.keys(localStorage).forEach(function(key){
			if (key.indexOf("alertCache:") != -1) {
                let localData = localStorage.getItem(key);
                if (localData == undefined || localData == null) {

                } else {
                    localData = JSON.parse(localData);
                    let splitName = key.split(":");
                    let alertName = splitName[2];
                    state.alertBadage.total += localData.length;
                    state.alertBadage[alertName] += localData.length;
                }
            }
		});
        console.log(state.alertBadage);
    },
    /**
     * setupup socket io connection
     * @param  {[type]} state [description]
     * @return {[type]}       [description]
     */
    socketBuild(state) {
        if (state.socket == null) {
            console.log("socket url: ", SOCKETIOURL);
            state.socket = io.connect(SOCKETIOURL, {
                transports: ['websocket'],
                upgrade: false
            });
            console.log(state.socket);

            state.socket.on("connect", (message) => {
                console.log("Connection data: " + message);
            });

			setTimeout(function () {
                state.connectId = state.socket.id
                // localStorage.setItem('connect_id', state.connect_id);
            }, 1000)
        } else {
            state.socket.connect();
        }
    },
    socketBuildWs(state) {
        if (state.socket == null) {
            console.log("socket url: ", SOCKETIOURL);
            state.socket = new WebSocket(socket);
            console.log(state.socket);

            // state.socket.onopen = function(message) {
            //     console.log("Connection data: " + message);
            // };

            setTimeout(function () {
                state.connectId = state.socket.id
                // localStorage.setItem('connect_id', state.connect_id);
            }, 1000)
        } else {
            state.socket.close();
        }
    },
    /**
     * destory socket
     * @param {object} state 
     */
    socketDestroy(state) {
        // console.log("destory socket");
        if (state.socket != null) {
            state.socket.disconnect();
        }
    },
    dismissSnackbar(state) {
        console.log("Access dismiss");
        state.snackbar = false;
    },
    permissionCheck(state) {
        let encryptData = localStorage.getItem("ul");
        if (encryptData != undefined && encryptData != null) {
            let existingPermission = CryptoJS.AES.decrypt(encryptData, ENCRYPT_KEY);
            let userLevel = parseInt(existingPermission.toString(CryptoJS.enc.Utf8).split(",")[0]);
            state.userLevel = parseInt(userLevel);
        }
        // console.log("Passing Page Permission: ", parseInt(pagePermission));
        // console.log("Calcualted User Permission: ", parseInt(userLevel));
        // if (parseInt(pagePermission) >= parseInt(userLevel)) return true;
        
        return false;
    },
    decryptBrokerId(state) {
        let encryptData = localStorage.getItem("bi");
        if (encryptData != undefined && encryptData != null) {
            let existingPermission = CryptoJS.AES.decrypt(encryptData, ENCRYPT_KEY);
            let brokerId = parseInt(existingPermission.toString(CryptoJS.enc.Utf8).split(",")[0]);
            state.brokerId = parseInt(brokerId);
        }
    }
}
export default mutations
