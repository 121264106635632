<template>
  	<v-app>
		<v-main>
			<router-view/>
			
			<v-snackbar v-model="$store.state.snackbar" :color="$store.state.snackcolor" :timeout="$store.state.snacktimeout" top> 
				{{ $store.state.snackmsg }}
				<template v-slot:action="{ attrs }">
					<v-btn :color="$store.state.snackclosecolor" 
						text v-bind="attrs" @click="dismiss()" >
						Close
					</v-btn>
				</template>
			</v-snackbar>
		</v-main>
	</v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld'
// import * as apiAlertChannel from "@components/api/pk/alert-channel";

export default {
  	name: 'App',
	components: {  },
  	data: () => ({ 
		snackmsg: "Error",
		snackstatus: true,
		snackclosecolor: "white",
        alertChannel: []
	}),
	methods: {
		dismiss() {
			this.$store.commit("dismissSnackbar");
			// this.$store.state.snakbar = false;
		},
	},
  	mounted () {
		// this.loadAlertChannel();
	}
}
</script>
