import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import DatetimePicker from 'vuetify-datetime-picker'
import JsonExcel from "vue-json-excel";
import ECharts from 'vue-echarts'
// import VueToast from 'vue-toast-notification';
import VueCodemirror from 'vue-codemirror'

// import base style
import 'codemirror/lib/codemirror.css'
// import more codemirror resource...

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";


// you can set default global options and events when Vue.use
Vue.use(VueCodemirror,  {
	options: { 
		tabSize: 4,
		theme: 'base16-dark',
		lineNumbers: true,
		line: true,
		mode: {
			name: 'javascript',
			json: true
		}
	},
	events: ['scroll']
})

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(Toast, {
	transition: "Vue-Toastification__bounce",
	maxToasts: 3,
	newestOnTop: true
});


Vue.config.productionTip = false
Vue.use(DatetimePicker)
// Vue.use(VueToast);

// register component to use
Vue.component('v-chart', ECharts)

Vue.component("downloadExcel", JsonExcel);

global.ENCRYPT_KEY = 'bfjfunds'

global.SOCKETIOURL = 'https://api-pk-socket.sigmatm.com.au/';

global.APIURL = 'https://api-pk-data.sigmatm.com.au/api/v1/';

router.beforeEach((to, from, next) => {

	store.commit("socketBuild");

	store.commit("permissionCheck");

	store.commit("decryptBrokerId");

	store.commit("alertCount");

	// let redirectName = to.name;
	// let data = JSON.parse(localStorage.getItem('bp')).frontPermission
	// if (data[redirectName]){
	//     next({ path: from.fullPath })
	//     return
	// }
	// socket io
	// store.commit("socketBuild");

	// web socket html5 feature
	// store.commit("socketBuildWs");

	// console.log("Broker ID for current user: ", store.state.brokerId);
	console.log('to data', to)
	if (to.matched.some(record => record.meta.requiresAuth)) {
		next();
	} else {
		next();
	}
})

router.afterEach((to, from, next) => {})

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
