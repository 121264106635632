<template>
	<div>
		<v-list-item>
			<v-list-item-content>
				<v-list-item-title class="title">Notification</v-list-item-title>
				<v-list-item-subtitle>Alerts</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-divider></v-divider>

		<v-expansion-panels accordion color="red" flat>
			<v-expansion-panel v-for="item in channel" :key="item">
				<v-expansion-panel-header v-on:click="changePanel(item)" color="#363636">
					<span v-if="item == 'big_lot'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.big_lot < 999">{{ alertBadage.big_lot }}</span>
							<span v-if="alertBadage.big_lot >= 999">999+</span>
						</v-btn> - Big Lot
					</span>
					<span v-if="item == 'mandate'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.mandate < 999">{{ alertBadage.mandate }}</span>
							<span v-if="alertBadage.mandate >= 999">999+</span>
						</v-btn> - Mandate
					</span>
					<span v-if="item == 'profit_taker'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.profit_taker < 999">{{ alertBadage.profit_taker }}</span>
							<span v-if="alertBadage.profit_taker >= 999">999+</span>
						</v-btn> - Profit Taker
					</span>
					<span v-if="item == 'locking_position'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.locking_position < 999">{{ alertBadage.locking_position }}</span>
							<span v-if="alertBadage.locking_position >= 999">999+</span>
						</v-btn> - Locking Position
					</span>
					<span v-if="item == 'trade_on_credit'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.trade_on_credit < 999">{{ alertBadage.trade_on_credit }}</span>
							<span v-if="alertBadage.trade_on_credit >= 999">999+</span>
						</v-btn> - Trade On Credit
					</span>
					<span v-if="item == 'large_exposure'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.large_exposure < 999">{{ alertBadage.large_exposure }}</span>
							<span v-if="alertBadage.large_exposure >= 999">999+</span>
						</v-btn> - Large Exposure
					</span>
					<span v-if="item == 'fast_trade'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.fast_trade < 999">{{ alertBadage.fast_trade }}</span>
							<span v-if="alertBadage.fast_trade >= 999">999+</span>
						</v-btn> - Fast Trade
					</span>
					<span v-if="item == 'watch_list'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.watch_list < 999">{{ alertBadage.watch_list }}</span>
							<span v-if="alertBadage.watch_list >= 999">999+</span>
						</v-btn> - Watch List
					</span>
					<span v-if="item == 'tick_check'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.tick_check < 999">{{ alertBadage.tick_check }}</span>
							<span v-if="alertBadage.tick_check >= 999">999+</span>
						</v-btn> - Tick Check
					</span>
					<span v-if="item == 'same_direction'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.same_direction < 999">{{ alertBadage.same_direction }}</span>
							<span v-if="alertBadage.same_direction >= 999">999+</span>
						</v-btn> - Same Direction
					</span>
					<span v-if="item == 'volume_alert'">
						<v-btn elevation="2" plain rounded x-small>
							<span v-if="alertBadage.volume_alert < 999">{{ alertBadage.volume_alert }}</span>
							<span v-if="alertBadage.volume_alert >= 999">999+</span>
						</v-btn> - Volume Alert
					</span>
					
				</v-expansion-panel-header>

				<v-expansion-panel-content color="#363636">
					<v-spacer></v-spacer>
					
					<v-select dense :items="brokerList" v-model="selectedAlertBroker" @focus="focusSelect"
						label="Choose Broker" item-text="broker" item-value="broker">
						<template slot="item" slot-scope="data" dense>
							<!-- <v-icon color="red" class="mr-1">mdi-close</v-icon> -->
							[{{ brokerAlertCount[data.item.broker] }}] - {{ data.item.broker }}
						</template>
					</v-select>

					<v-alert type="info" dense v-if="selectedAlertBroker != '' && displayData.length == 0">
						No Alert Found
					</v-alert>
					<template v-if="displayData.length != 0" style="height: 300px; overflow:scroll;">
						<v-card class="mx-auto mb-3" v-for="(item, index) in displayData" :key="index" color="#34495e">
							<v-card-text>
								<p class="mb-1" 
									v-if="n != 'alert_name' && n != 'broker' && n != 'message'" 
									v-for="(v, n, i) in item" :key="i">
									{{ n }}: {{ v }}
								</p>
								<!--
								<div></div>
								<p class="display-1 text--primary">
									be•nev•o•lent
								</p>
								<p>adjective</p>
								<div class="text--primary">
									well meaning and kindly.<br>
									"a benevolent smile"
								</div>
								-->
							</v-card-text>
						</v-card>
					</template>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-row>
			<v-col cols="12" sm="12" md="12" lg="12" xl="12">
				<v-spacer></v-spacer>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<v-list-item>
			<v-list-item-content>
				<v-list-item-subtitle>Remove</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
		<v-container class="spacing-playground pa-4" fluid>
			<v-row>
				<v-col cols="12" sm="12" md="6" lg="6" xl="6">
					<v-select dense :items="brokerList" v-model="selectedDeleteBroker" @focus="focusSelectDelete"
						label="Choose Broker" item-text="broker" item-value="broker">
						<template slot="item" slot-scope="data" dense>
							{{ data.item.broker }}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" sm="12" md="6" lg="6" xl="6">
					<v-select dense :items="channel" v-model="selectedDeleteChannel" @focus="focusSelectDelete"
						label="Choose Channel" item-text="item" item-value="item">
						<template slot="item" slot-scope="data" dense>
							{{ data.item }}
						</template>
					</v-select>
				</v-col>
				<v-col cols="12" sm="12" md="12" lg="12" xl="12">
					<v-btn block small @click="showConfirm">Delete Cache</v-btn>
				</v-col>
			</v-row>
			<v-dialog v-model="confirmVisible" max-width="400">
				<v-card>
					<v-card-title class="headline">
						Confirm
					</v-card-title>
					<v-card-text>
						<span v-if="selectedDeleteChannel != ''">
							Confirm to remove alert cache for <strong>{{ selectedDeleteChannel }}</strong> 
							under broker {{ selectedDeleteBroker }}
						</span> 
						<span v-if="selectedDeleteChannel == ''">
							Confirm to remove all alert cache under broker {{ selectedDeleteBroker }}
						</span> 
					</v-card-text>

					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="confirmVisible = false">
							Cancel
						</v-btn>
						<v-btn color="red darken-1" text @click="confirmVisible = false">
							Remove
						</v-btn>
					</v-card-actions>
				</v-card>
				</v-dialog>
				
		</v-container>
	</div>
</template>
<script>
import {mapState} from "vuex";

export default {
	components: {},
	props: {
		channel: null
	},
	data() {
		return {
			selectedPanel: "",
			channelList: [],
			displayData: [],
			brokerList: [],
			selectedAlertBroker: [],
			selectedPanel: "",
			brokerAlertCount: {},
			brokerAlertCountDelete: 0,
			selectedDeleteChannel: "",
			selectedDeleteBroker: "",
			confirmVisible: false
		}
	},
	computed: {
		...mapState(["alertBadage"])
	},
	watch: {
		selectedAlertBroker(nv, ov) {
			if (this.selectedPanel != "") {
				let localKey = "alertCache:" + nv + ":" + this.selectedPanel;
				let localData = JSON.parse(localStorage.getItem(localKey));

				if (localData != null) {
					console.log("🚀 ~ file: ComponentAlertNotificationPanel.vue ~ line 85 ~ selectedAlertBroker ~ localData", localData)
					this.displayData = localData.filter(item => item.broker == nv);
				} else {
					this.displayData = [];
				}
			}
			// this.$store.commit("alertCount");
		}
	},
	methods: {
		removeCache() {

		},
		showConfirm() {
			this.confirmVisible = true;
		},
		focusSelectDelete(value) {
			let emptyBroker = (this.selectedDeleteBroker == "" || this.selectedDeleteBroker == "");
			let emptyAlert = (this.selectedDeleteChannel == "" || this.selectedDeleteChannel == "");
			
		},
		focusSelect(value) {
			this.brokerAlertCount = {};

			let i = 0;
			while (i < this.brokerList.length) {
				let broker = this.brokerList[i].broker;
				console.log("Found: ", this.brokerList[i]);
				let key = "alertCache:" + broker + ":" + this.selectedPanel;
				let localData = localStorage.getItem(key);
				if (localData == undefined || localData == null) {
					this.brokerAlertCount[broker] = 0;
				} else {
					localData = JSON.parse(localData);
					this.brokerAlertCount[broker] = localData.length;
				}
				i += 1;
			}
		},
		changePanel(panel) {
			this.selectedPanel = panel;
			if (this.selectedAlertBroker != "") {
				let localKey = "alertCache:" + this.selectedAlertBroker + ":" + this.selectedPanel;
				let localData = JSON.parse(localStorage.getItem(localKey));
				
				if (localData != null) {
					console.log("🚀 ~ file: ComponentAlertNotificationPanel.vue ~ line 85 ~ selectedAlertBroker ~ localData", localData)
					this.displayData = localData.filter(item => item.broker == this.selectedAlertBroker);
				} else {
					this.displayData = [];
				}
			}
			// this.$store.commit("alertCount");
		}
	},
	mounted() {
	}
}
</script>