<template>
    <div>
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="title">IHT Control Panel</v-list-item-title>
                <v-list-item-subtitle>IHT</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense nav>
            <template v-for="item in items">
                <v-list-group v-if="item.children && item.active" :key="item.text" v-model="item.model" :prepend-icon="item.model ? item.icon : item['icon-alt']" append-icon="">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <template v-for="(child, i) in item.children">
                        <v-list-item v-if="child.active" :key="i" @click.stop="child.type === 'link' ? goPage(child) : child.func.apply(this)">
                            <v-list-item-action v-if="child.icon">
                                <v-icon>{{ child.icon }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ child.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>

                <v-list-item :key="item.text" v-if="!item.children && item.active" @click.stop="item.type === 'link' ? goPage(item) : item.func.apply(this)">
                    <v-list-item-action>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.text }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <v-list-item @click.stop="logout()">
                <v-list-item-action>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>
                        Log Out
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>
<style src="@assets/style.css"></style>

<script>
import { mapState } from "vuex";
// import * as apiAlertChannel from "@components/api/pk/alert-channel";
export default {
    data() {
        return {
            items: [
                {
                    icon: "mdi-book-multiple",
                    text: "Client List",
                    name: "book",
                    type: "link",
                    ref: "/",
                    active: 1,
                },
                {
                    icon: "mdi-call-split",
                    text: "Search",
                    name: "search",
                    type: "link",
                    ref: "/search",
                    active: 1,
                },
            ],
            right: null,
        };
    },
    // computed: {
    // 	...mapState([
    // 		'userLevel',
    // 	])
    // },
    methods: {
        goPage(data) {
            this.$router.push(data.ref).catch(() => {});
        },
        logout() {
            localStorage.clear();
            let routeData = this.$router.push({ name: "Signin" });
            this.$store.state.loginStatus = -1;
        },
        // loadAlertChannel() {
		// 	let self = this;
		// 	apiAlertChannel.fetch().then(res => {
		// 		console.log(res);
		// 		self.alertChannel = res.data;
		// 		console.log("🚀 ~ file: Alarm.vue ~ line 132 ~ apiAlertChannel.fetch ~ self.alertChannel", self.alertChannel)
		// 	})
		// },
    },
    mounted() {
        // let data = JSON.parse(localStorage.getItem("bp")).frontPermission;
        // for (let item of this.items) {
        //     let name = item.name;
        //     if (data[name]) {
        //         item.active = 1;
        //         if (data[name].child.length > 0) {
        //             let children = data[name].child;
        //             if (item.children) {
        //                 for (let child of item.children) {
        //                     if (children.includes(child.name)) {
        //                         child.active = 1;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
        // this.loadAlertChannel();
        // this.$store.commit("socketBuild");
        // let socket = this.$store.state.socket;
        // for (let channel of this.alertChannel) {
        //     let selectedChannel = this.selectedBroker + "-AlertsChannel:" + channel;
        //     console.log(
        //         "🚀 ~ file: Alarm.vue ~ line 105 ~ selectedBroker ~ selectedChannel",
        //         selectedChannel
        //     );
        //     socket.on(selectedChannel, (data) => {
        //         data = JSON.parse(data);
        //         let newData = data.map((el) => {
        //             let custom = el.custom;
        //             el = Object.assign(el, custom);
        //             delete el.custom;
        //             return el;
        //         });
        //         console.log(data);
        //     });
        // }
    },
};
</script>
