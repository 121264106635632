<template>
	<v-app id="inspire">
		<!--
			<v-system-bar app><v-spacer></v-spacer><v-icon>mdi-square</v-icon><v-icon>mdi-circle</v-icon><v-icon>mdi-triangle</v-icon></v-system-bar>
		-->
		
		<v-app-bar app color="#1A3958" dark>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-toolbar-title>IHT Central Control Panel</v-toolbar-title>
      		<v-spacer></v-spacer>
			<v-app-bar-nav-icon @click="alertDrawer = !alertDrawer" right style="margin-right: 20px;">
				<v-badge :content="alertBadage.total" :value="alertBadage.total" color="red" overlap v-if="alertBadage.total < 999">
					<v-icon>mdi-bell</v-icon>
				</v-badge>
				<v-badge content="999+" :value="alertBadage.total" color="red" overlap v-if="alertBadage.total > 999">
					<v-icon>mdi-bell</v-icon>
				</v-badge>
			</v-app-bar-nav-icon>
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" fixed temporary>
			<TheMenu/>
		</v-navigation-drawer>

		<v-navigation-drawer v-model="alertDrawer" right fixed temporary width="400">
			<AlertNotificationPanel :channel="channelList"/>
		</v-navigation-drawer>

		<v-main style="padding-top:10px;">
			<v-container fluid>
				<router-view></router-view>
				
				<v-overlay :value="this.$store.state.overlay" align="center">
					<v-progress-circular indeterminate :size="70" :width="7" color="primary"></v-progress-circular>
					<p>Loading Data...</p>
				</v-overlay>

				<!-- <v-fab-transition>
					<v-btn :key="'mdi-share-variant'" :color="'red'" @click="alertDrawer = !alertDrawer"
						fixed fab dark bottom right class="v-btn--example">
						<v-icon>mdi-bell</v-icon>
					</v-btn>
				</v-fab-transition> -->
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import TheMenu from "@components/layout/TheMenu.vue";
import AlertNotificationPanel from "@components/ComponentAlertNotificationPanel.vue";
import {mapState} from "vuex";

export default {
	name: 'Layout',
	components: { TheMenu, AlertNotificationPanel },
	data: () => ({ drawer: null, alertDrawer: null, channelList: [] }),
	computed: {
		...mapState([
			"alertBadage"
		])
	},
	watch: {
		alertDrawer(nv, ov) {
			if (nv) {
				this.$store.commit("alertCount");
				this.channelList = JSON.parse(localStorage.getItem("alertChannel"));
			}
		}
	},
	methods: {
	},
	mounted () {

	}
}
</script>
